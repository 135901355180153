<template>
  <div class="casino-cashier csn-withdrawal">
    <h1>{{ t('title') }}</h1>
    <p>{{ t('description') }}</p>
    <TabPanels
      :blockedStep="blockedStep"
      :initialStep="step"
      :stepNumber="3"
      :titleList="titleList"
      :reset="reset"
      :backButtonList="backButtonList"
      @on-click-header-2="onClickStep2Header"
    >
      <template v-slot:step-1>
        <p class="ml-20" v-if="!hasDeposits">
          {{ t('no_deposit') }}
        </p>

        <Loader v-if="showsStepOneLoader" />
        <div v-if="showsStepOneData" class="row csn-withdrawal-panel-body-1">
          <Route
            v-for="(payment, id) in WITHDRAWAL_METHOD_DICTIONARY"
            :key="id"
            class="provider-box col-md-2 csn-withdrawal-provider-box"
            :to="{
              name: withdrawalAddRoute,
              query: {
                paymentMethodId: payment.paymentMethodId,
                paymentSourceType: getPaymentSourceType(payment),
              },
            }"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="payment-method-box csn-withdrawal-card">
                  <img
                    :src="getLogoPath(payment.logo)"
                    :alt="payment.paymentMethodName"
                  />
                </div>
              </div>
            </div>
            <div class="row limits">
              <div class="col-md-3">{{ t('min') }}</div>
              <div class="col-md-9 text-right">
                {{
                  format({
                    number: payment.minAmountLimit,
                    currency: payment.currency,
                  })
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="line"></div>
              </div>
            </div>
            <div class="row limits">
              <div class="col-md-3">{{ t('max') }}</div>
              <div class="col-md-9 text-right">
                {{
                  format({
                    number: payment.maxAmountLimit,
                    currency: payment.currency,
                  })
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="line"></div>
              </div>
            </div>
          </Route>
        </div>
      </template>

      <template v-slot:step-2>
        <div v-if="step === 2" class="csn-withdrawal-step-two-container">
          <Loader v-if="isConfirmationDataPending" />
          <p v-else-if="isOptedMethodEmpty">
            {{ t('withdrawal_method_not_found') }}
          </p>
          <div v-else>
            <Message :type="messageType" :message="message" :hasBottom="true" />
            <div class="cashier-summary">
              <div class="row csn-withdrawal-step-two-row">
                <div class="col-md-6 text-white">
                  {{ t('currency') }}
                </div>
                <div class="col-md-6">
                  {{ optedWithdrawalMethod.currency }}
                </div>
              </div>

              <div class="row csn-withdrawal-step-two-row">
                <div class="col-md-6 text-white">
                  {{ t('payment_method_name') }}
                </div>
                <div class="col-md-6">
                  {{ optedWithdrawalMethod.paymentMethodName }}
                </div>
              </div>

              <div class="row csn-withdrawal-step-two-row">
                <div class="col-md-6 text-white">
                  {{ t('owner_name') }}
                </div>
                <div class="col-md-6">
                  {{
                    isOptedMethodCreditCard
                      ? optedWithdrawalMethod.paymentSource.creditCard
                          .holderName
                      : optedWithdrawalMethod.paymentSource.bankAccount
                          .holderName
                  }}
                </div>
              </div>

              <div
                v-if="isOptedMethodCreditCard"
                class="row csn-withdrawal-step-two-row"
              >
                <div class="col-md-6 text-white">
                  {{ t('payment_card_number') }}
                </div>
                <div class="col-md-6">
                  {{
                    `${cardSummaryAsterisks} ${optedWithdrawalMethod.paymentSource.creditCard.cardSummary}`
                  }}
                </div>
              </div>

              <div
                v-if="isOptedMethodCreditCard"
                class="row csn-withdrawal-step-two-row"
              >
                <div class="col-md-6 text-white">
                  {{ t('payment_card_expire_date') }}
                </div>
                <div class="col-md-6">
                  {{
                    optedWithdrawalMethod.paymentSource.creditCard.expiryDate
                  }}
                </div>
              </div>

              <div v-else class="row csn-withdrawal-step-two-row">
                <div class="col-md-6 text-white">
                  {{ t('payment_bank_account_number') }}
                </div>
                <div class="col-md-6">
                  {{ optedWithdrawalMethod.paymentSource.bankAccount.iban }}
                </div>
              </div>

              <div class="row csn-withdrawal-step-two-row">
                <div class="col-md-6 text-white">
                  {{ t('your_account_balance') }}
                </div>
                <div class="col-md-6">
                  {{ accountBalance }}
                </div>
              </div>

              <Form :ref="formRef">
                <div class="row csn-withdrawal-step-two-row">
                  <div class="col-md-6 text-white"></div>

                  <div class="col-md-6">
                    <MoneyField
                      :type="inputType.TEXT"
                      :name="name.AMOUNT"
                      v-model="formData[name.AMOUNT]"
                      :validators="[
                        validator.required,
                        validator.meetsMin(moneyLimit.min),
                        validator.meetsMax(moneyLimit.max),
                      ]"
                      :description="moneyFieldDescription"
                      :serverError="serverError[name.AMOUNT]"
                      :sideLabel="t('amount')"
                      :hasDecimal="true"
                      placeholder="0.00"
                    />
                  </div>
                </div>

                <div class="row csn-withdrawal-step-two-row">
                  <div class="col-md-12">
                    <button
                      id="withdrawalForm"
                      class="btn casino-btn casino-btn-default casino-btn-main-menu csn-withdrawal-submit-btn"
                      @click="submitForm"
                      :disabled="isSubmitting"
                    >
                      <ButtonLoader v-if="isSubmitting" />
                      <span v-else>{{ t('submit') }}</span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:step-3>
        <div v-if="step === 3" class="csn-withdrawal-step-two-container">
          <Loader v-if="!step3msg.text" />
          <Message :type="step3msg.messageType" :message="step3msg.text" />
        </div>
      </template>
    </TabPanels>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import {
  WITHDRAWAL_PANEL,
  Module,
  Digit,
  WITHDRAWAL_METHOD_DICTIONARY,
  IS_WITHDRAWAL_METHOD_PENDING,
  RouteName,
  UPDATE_CASINO_INFO,
  EMPTY_STRING,
  EMPTY_OBJECT,
  SLASH,
  HAS_DEPOSIT,
  CASINO_INFO,
  ResponseState,
} from '@/constants'
import {
  isNil,
  isEmpty,
  formatNumberLocale as format,
  head,
  navigateTo,
} from '@/helpers'
import { formMixin } from '@/mixins'
import { AuthApi } from '@/api'

const emptyOptedMethod = {}
const asterisks = '** **** **** **** **** ****'
const step3MsgInitialValue = EMPTY_OBJECT

const name = {
  AMOUNT: 'amount',
}
const formData = {
  [name.AMOUNT]: EMPTY_STRING,
}

export default {
  name: WITHDRAWAL_PANEL,
  mixins: [formMixin],
  components: {
    TabPanels: () => import('@/components/TabPanels'),
    Loader: () => import('@/components/Loader'),
    MoneyField: () => import('@/components/MoneyField'),
    Form: () => import('@/components/FormData'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    Route: () => import('@/components/Route'),
  },
  data: () => ({
    step: null,
    blockedStep: null,
    optedWithdrawalMethod: null,
    formData: { ...formData },
    isSubmitting: false,
    logoImageList: [], // NOTE: mutable array
    step3msg: step3MsgInitialValue,
  }),
  computed: {
    ...mapState(Module.WITHDRAWAL_METHOD, [
      WITHDRAWAL_METHOD_DICTIONARY,
      IS_WITHDRAWAL_METHOD_PENDING,
    ]),
    ...mapState(Module.USER, [HAS_DEPOSIT]),
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    t() {
      return this.$createComponentTranslator(WITHDRAWAL_PANEL)
    },
    withdrawalAddRoute: () => RouteName.WITHDRAWAL_ADD,
    cardSummaryAsterisks: () => asterisks,
    name: () => name,
    hasDeposits() {
      return this.HAS_DEPOSIT
    },
    isInitialDataPending() {
      return this.IS_WITHDRAWAL_METHOD_PENDING
    },
    isConfirmationDataPending() {
      return this.isInitialDataPending || isNil(this.optedWithdrawalMethod)
    },
    isOptedMethodEmpty() {
      return isEmpty(this.optedWithdrawalMethod)
    },
    moneyLimit() {
      return {
        min:
          this.optedWithdrawalMethod?.minAmountLimit / Digit.ONE_HUNDRED ||
          Digit.NOUGHT,
        max:
          this.optedWithdrawalMethod?.maxAmountLimit / Digit.ONE_HUNDRED ||
          Digit.NOUGHT,
      }
    },
    moneyFieldDescription() {
      const {
        minAmountLimit,
        maxAmountLimit,
        currency,
      } = this.optedWithdrawalMethod
      const minValue = format({ number: minAmountLimit, currency })
      const maxValue = format({ number: maxAmountLimit, currency })

      return `${this.t('min')}: ${minValue} / ${this.t('max')}: ${maxValue}`
    },
    titleList() {
      return [
        this.t('select_withdrawal_method'),
        this.t('withdrawal_confirmation'),
        this.t('account_status'),
      ]
    },
    showsStepOneLoader() {
      return (
        this.hasDeposits && this.step === Digit.ONE && this.isInitialDataPending
      )
    },
    showsStepOneData() {
      return (
        this.hasDeposits &&
        this.step === Digit.ONE &&
        !this.isInitialDataPending
      )
    },
    currentLocation() {
      return this.$router.history.current
    },
    token() {
      return this.currentLocation.name === RouteName.WITHDRAWAL_STATUS
        ? this.$route.query.token
        : null
    },
    accountBalance() {
      return this.CASINO_INFO.accountBalance || EMPTY_STRING
    },
    withdrawalMethodDictionary() {
      const list = {
        bankAccount: {},
        creditCard: {},
      }

      Object.values(this.WITHDRAWAL_METHOD_DICTIONARY || {}).forEach(
        ({ paymentSource, paymentMethodId, ...props } = {}) => {
          if (!paymentSource) {
            return
          }

          const isBankAccount = !!paymentSource?.bankAccount
          const method = {
            ...props,
            paymentSource: isBankAccount
              ? paymentSource.bankAccount
              : paymentSource.creditCard,
          }

          isBankAccount
            ? (list.bankAccount[paymentMethodId] = method)
            : (list.creditCard[paymentMethodId] = method)
        },
      )

      return list
    },
    isOptedMethodCreditCard() {
      return !!this.optedWithdrawalMethod?.paymentSource?.creditCard
    },
    backButtonList() {
      return [null, 1, 1]
    },
  },
  watch: {
    WITHDRAWAL_METHOD_DICTIONARY: {
      immediate: true,
      handler(value) {
        if (this.currentLocation.name !== RouteName.WITHDRAWAL_ADD) {
          return
        }

        const optedMethodId = this.currentLocation.query.paymentMethodId
        this.optedWithdrawalMethod = value[optedMethodId] || emptyOptedMethod
      },
    },
    formData: {
      deep: true,
      handler(currentFormData) {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)

        if (!Object.keys(this.serverError).length) {
          return
        }

        Object.keys(this.serverError).forEach((key) => {
          if (currentFormData[key] === this.formDataSubmitCopy[key]) {
            return
          }

          const newServerError = { ...this.serverError }
          delete newServerError[key]
          this.serverError = newServerError
        })
      },
    },
    currentLocation: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.currentLocation.name === RouteName.WITHDRAWAL) {
          this.step = Digit.ONE
          this.blockedStep = Digit.TWO
          return
        }

        if (this.currentLocation.name === RouteName.WITHDRAWAL_ADD) {
          this.step = this.hasDeposits ? Digit.TWO : Digit.ONE
          this.blockedStep = Digit.THREE
        }

        if (this.currentLocation.name === RouteName.WITHDRAWAL_STATUS) {
          this.step = Digit.THREE
          this.blockedStep = null
          const id = this.$router.history.current.query.token

          try {
            const { data, state, msg } = await AuthApi.getWithdrawalDetails({
              id,
            })

            if (state === ResponseState.OK) {
              this.step3msg = {
                type: this.messageTypeEnum.SUCCESS,
                text: data.info,
              }
            }

            if (state === ResponseState.ERROR) {
              this.step3msg = {
                type: this.messageTypeEnum.ERROR,
                text: msg,
              }
            }
          } catch (e) {
            console.error(e)
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(Module.CASINO_INFO, [UPDATE_CASINO_INFO]),
    getLogoPath(logoPath) {
      const splitPath = logoPath.split(SLASH)
      const fileName = splitPath[splitPath.length - Digit.ONE]

      return this.logoImageList.find((image) => image.fileName === fileName)
        ?.src
    },
    importAll(r) {
      r.keys().forEach((key) => {
        const imageObject = {
          src: r(key),
          fileName: key.split(SLASH)[Digit.ONE],
        }

        return this.logoImageList.push(imageObject)
      })
    },
    reset() {
      this.$router.history.current.name !== RouteName.WITHDRAWAL &&
        navigateTo({ name: RouteName.WITHDRAWAL })

      this.step = Digit.ONE
      this.blockedStep = Digit.TWO
    },
    format: (args) => format(args),
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }
      this.isSubmitting = true

      try {
        const request = {
          paymentMethodId: this.optedWithdrawalMethod.paymentMethodId,
          paymentSourceType: this.optedWithdrawalMethod.paymentSource.creditCard
            .paymentSourceType,
          form: {
            amount: this.formData[name.AMOUNT],
          },
        }

        const response = await AuthApi.addWithdrawal(request)

        if (!this.validateResponse(response)) {
          return
        }

        const { data, state, msg } = response
        this.blockedStep = null

        if (state === this.responseState.OK) {
          await this.$refs[this.formRef].reset()

          this.serverError = {}
          this.formDataSubmitCopy = {}
          // this.message = head(msg)
          // this.messageType = this.messageTypeEnum.SUCCESS

          this.UPDATE_CASINO_INFO()
          navigateTo({
            name: RouteName.WITHDRAWAL_STATUS,
            query: { token: data.token },
          })
        }

        if (state === this.responseState.ERROR) {
          const serverError = {}
          this.message = head(msg)
          this.messageType = this.messageTypeEnum.FAILURE

          Object.keys(data.errors || EMPTY_OBJECT).forEach((key) => {
            serverError[key] = data.errors[key].msg
          })

          this.serverError = serverError
          this.formDataSubmitCopy = { ...this.formData }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
    onClickStep2Header() {
      this.currentLocation.name !== RouteName.WITHDRAWAL_ADD &&
        navigateTo({ name: RouteName.WITHDRAWAL_ADD })
    },
    getPaymentSourceType(payment) {
      return (
        payment.paymentSource?.creditCard?.paymentSourceType ||
        payment.paymentSource?.bankAccount?.paymentSourceType
      )
    },
  },
  created() {
    this.importAll(
      require.context(
        '../../assets/images/payment-method',
        true,
        /\.(png|jpg|jpeg|svg)$/,
      ),
    )
  },
}
</script>
